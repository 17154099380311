import { createI18n } from 'vue-i18n'
import tw from '../i18n/tw.json'
import cn from '../i18n/cn.json'
import en from '../i18n/en.json'

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute()
  const customerLang = route.query.lang
  const agentLang = localStorage.getItem('langs')

  const validLocales = ['tw', 'cn', 'en']
  const storedLocale = customerLang || agentLang
  const locale = validLocales.includes(storedLocale) ? storedLocale : 'tw'

  const i18n = createI18n({
    legacy: false,
    locale,
    fallbackLocale: 'tw',
    messages: {
      tw,
      cn,
      en
    }
  })

  // 將i18n實例注入Vue應用
  nuxtApp.vueApp.use(i18n)

  // 提供i18n.global給Pinia使用（包含t）
  nuxtApp.provide('i18n', i18n.global)
})
