import plugin_vue3_A0OWXRrUgq from "C:/develop/workspace/aican_v2_ui/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/develop/workspace/aican_v2_ui/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "C:/develop/workspace/aican_v2_ui/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/develop/workspace/aican_v2_ui/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "C:/develop/workspace/aican_v2_ui/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "C:/develop/workspace/aican_v2_ui/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "C:/develop/workspace/aican_v2_ui/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_client_i18n_EJtKqFefby from "C:/develop/workspace/aican_v2_ui/plugins/plugin.client.i18n.js";
import runtime_config_9dAVxnlU3J from "C:/develop/workspace/aican_v2_ui/plugins/runtime-config.js";
import directives_GDwrTGmylM from "C:/develop/workspace/aican_v2_ui/plugins/directives.js";
import loading_client_zL50Ksvvei from "C:/develop/workspace/aican_v2_ui/plugins/loading.client.js";
import plugin_client_piniaPersistedstate_lsUE3yzXjW from "C:/develop/workspace/aican_v2_ui/plugins/plugin.client.piniaPersistedstate.js";
import plugin_client_vuetify_0I9Qb5vn0D from "C:/develop/workspace/aican_v2_ui/plugins/plugin.client.vuetify.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  plugin_client_i18n_EJtKqFefby,
  runtime_config_9dAVxnlU3J,
  directives_GDwrTGmylM,
  loading_client_zL50Ksvvei,
  plugin_client_piniaPersistedstate_lsUE3yzXjW,
  plugin_client_vuetify_0I9Qb5vn0D
]